import React from "react";
import ReactDOM from "react-dom";
import { Suspense, lazy } from 'react';
import "./index.scss";

// import * as serviceWorker from "./serviceWorker";
import { Provider } from "mobx-react";
import store from "./libs/react-mpk/store";

import counterpart from 'counterpart';
import { merge } from 'lodash';

// Mandatori lokalisasi bahasa component di dalam librari react-mpk
import mpkId from './libs/react-mpk/locales/id.json';
import mpkEn from './libs/react-mpk/locales/en.json';

// lokalisasi bahasa dalam aplikasi
import id from './assets/locales/id.json';
import en from './assets/locales/en.json';
import Loading from "./modules/_modules/Component/Loading";

const ID = merge(mpkId, id);
const EN = merge(mpkEn, en);

// Registrasi lokalisasi
counterpart.registerTranslations('id', ID);
counterpart.registerTranslations('en', EN);

const App = lazy(() => import('./App'))

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <Provider {...store}>
      <App/>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
